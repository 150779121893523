import React, { useState, useEffect,useRef } from 'react';
import axios from 'axios';
import "./chat.css"
import Logo from "../img/LogoHeader.png";

function ChatAssistant() {
    const queryParameters = new URLSearchParams(window.location.search);
    const match = queryParameters.get("match");
    const solicitud = queryParameters.get("solicitud");
    const [messages, setMessages] = useState([{"role":"assistant","content":"Preguntame por estos profesionales o sus métodos de atención"}]);
    const [input, setInput] = useState("");
   const [isChatboxMinimized, setIsChatboxMinimized] = useState(false);
    const messagesEndRef = useRef(null);

    // Efecto para minimizar el chatbox si no se escribe por 10 segundos
        const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" }); // Realiza el scroll al final
    };

    useEffect(() => {
        scrollToBottom(); // Realiza el scroll cada vez que cambien los mensajes
    }, [messages]);
    useEffect(() => {
        if (input.trim()) {
            setIsChatboxMinimized(false); // Si el usuario escribe, se asegura de que esté abierto
            return; // No continúa si hay texto en el input
        }
        if (messages.length>0 ){
            setIsChatboxMinimized(false)
        }
        else{
        const timeout = setTimeout(() => {
            setIsChatboxMinimized(true); // Minimiza el chatbox si no hay interacción
        }, 1500); // 10 segundos de inactividad

        return () => clearTimeout(timeout); }// Limpia el timeout al desmontar o reiniciar
    }, [input]);

    const sendMessage = async () => {
        if (!input.trim()) return;

        const userMessage = { role: "user", content: input };
        setMessages([...messages, userMessage]);

        try {
            const response = await axios.post(`/api/chatassist/${match}/${solicitud}`, {
                message: input, role:"user"
            });

            const botMessage = { role: "assistant", content: response.data.reply };
            setMessages((prev) => [...prev, botMessage]);
        } catch (error) {
            console.error("Error al enviar el mensaje:", error);
            const errorMessage = { role: "assistant", content: "Lo siento, ocurrió un error. Inténtalo de nuevo." };
            setMessages((prev) => [...prev, errorMessage]);
        }

        setInput("");
    };

    return (
        <div style={{maxWidth:"30%",  position: "fixed", /* Fijo en la parte inferior */
    bottom: "0", /* Pegado al fondo */
  
    right: "0",
    zIndex:"1000",background:"#fbfbfb",borderRadius:"5px 5px 0px 0px"}}
    className="ChatboxAI"
    >
            
           <div style={{ 
    display: "flex", 
    flexDirection: "column-reverse", 

  height:"100%",
    position: "relative" 
}}>

    
            
    {/* Contenedor del Input */}
    <div className="chat-input">
        <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={(e) => {
                if (e.key === "Enter") {
                    sendMessage();
                }
            }}
            placeholder={messages.length>2?"¿Tienes otra consulta?":"¿Tienes alguna consulta?"}
        />
        <button className={`elemento ${isChatboxMinimized ? "buttonchatexpanded" : ""}`} onClick={sendMessage}>
            ➤ {/* Ícono de enviar */}
        </button>
    </div>

    {/* Contenedor de mensajes */}
        
        <div
            style={{
                flex: 1,
                border: "1px solid #ccc",
                
                padding: "0",
                height: "auto",
            
            
                maxHeight: "300px", // Máximo espacio que puede ocupar el chat
                overflowY: "auto",
                transition: "all 0.3s ease-in-out",
            }}
        >
           
            <div style={{ display:isChatboxMinimized?"none":"",}} >

            {messages.map((msg, index) => (
                <div key={index} style={{ textAlign: msg.role === "assistant" ? "left" : "right" ,minWidth:"100%"}}>
                    <p style={{
                        borderRadius: "12px", 
                        background: msg.role === "assistant" ? "#fcefe4e1" : "#87a7b44b", 
                        padding: "5px", 
                        margin: "3px",
                        maxWidth:"90%",
                        justifyContent:msg.role === "assistant" ? "left" : "right",
                        justifySelf:msg.role === "assistant" ? "left" : "right",
                        whiteSpace:"pre-line"
                        
                    }}>
                        <span style={{ color: msg.role === "assistant" ? "#ee6c4d" : "#154480", maxWidth:"80%",wordWrap:"break-word" }}>
                            {msg.content}
                        </span>
                    </p>
                </div>
            ))}
              <div ref={messagesEndRef} /> 
            </div>
        </div>
        <div style={{ display:"flex",flex: 1, justifyContent:"space-between", backgroundColor:"#98c1d8", marginBottom:"10px", margin:"0px",padding:"3px",borderRadius:"5px 5px 0px 0px",}}> 
              <div style={{display:"flex"}}><span><img
            
            href="/"
            src={Logo}
            alt="Logo Vinculandose"
            style={{width:"25px",borderRadius:"50%",height:"25px",margin:"3px"}}
          /></span> <p style={{color:"white", fontWeight:"600"}}>VincularSE</p></div> <button
                onClick={() => setIsChatboxMinimized((prev) => !prev)}
                style={{

                    backgroundColor: "#98c1d8",
                    color: "white",
                    border: "none",
                    borderRadius: "50%",
                    width: "30px",
                    height: "30px",
                    display: (messages.length>0) ? "block" :"none",
                    alignItems: "right",
                    justifyContent: "right",
                    cursor: "pointer",
                    zIndex: 10,
                    fontSize: "16px",
                }}
            >
                {isChatboxMinimized ? "▲" : "▼"}
            </button></div>

</div>

        </div>
    );
}

export default ChatAssistant;
