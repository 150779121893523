import axios from "axios";
import { useState, useEffect } from "react";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import { addMinutes } from "date-fns";
import ModalButton from "../Modals/ModalButton";
import { es } from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import PropTypes from "prop-types";
import "./Calendario.css";
import Cookies from "js-cookie";

registerLocale("es", es);
setDefaultLocale("es", es);

export function EditCalendarModal({
  services,
  setServices,
  setDate,
  dateInput,
}) {
  EditCalendarModal.propTypes = {
    services: PropTypes.array,
    setServices: PropTypes.func,
    setDate: PropTypes.func,
    dateInput: PropTypes.instanceOf(Date),
  };
  const isValidDate = (date) => date instanceof Date && !isNaN(date);
  const [toShow,setToShow] =useState(false)
  const [tipomodal,setTipoModal] =useState("false")
  const [startDate, setStartDate] = useState(
    isValidDate(dateInput) ? dateInput : new Date(),
  );
  useEffect(() => {
    if (isValidDate(dateInput)) {
      setStartDate(dateInput);
    }
  }, [dateInput]);
  const [addedMessage, setAddedMessage] = useState(false);
  const [repeat, setRepeat] = useState(false);
  const [repeatedDays, setRepeatedDays] = useState([
    { cap: "L", selected: false },
    { cap: "M", selected: false },
    { cap: "X", selected: false },
    { cap: "J", selected: false },
    { cap: "V", selected: false },
    { cap: "S", selected: false },
    { cap: "D", selected: false },
  ]);
  const semanalmente = "Semanalmente";
  const baseConfig = { borderRadius: "50%", width: "2rem", height: "2rem" };

  const resetRepeatedDays = (position) => {
    const nextDays = repeatedDays.map((item, idx) => {
      if (position == idx) {
        return { ...item, selected: true };
      }
      return { ...item, selected: false };
    });
    setRepeatedDays(nextDays);
  };

  const updateSelectedRepeatedDates = (position) => {
    const nextRep = repeatedDays.map((item, idx) => {
      if (idx != position) {
        return item;
      } else {
        return {
          ...item,
          selected: !item.selected,
        };
      }
    });
    setRepeatedDays(nextRep);
  };

  const getRepeatedDays = () => {
    const repDays = [];
    repeatedDays.map((item) => {
      if (item.selected === true) {
        repDays.push(item.cap);
      }
    });
    return repDays;
  };
  const [savedData,setSavedData]=useState(false)
  
  const getCheckedServices = () => {
    const checkedServices = [];
    services.map((item) => {
      if (item.checked) {
        checkedServices.push(item);
      }
    });
    return checkedServices;
  };

  const checkSameDuration = () => {
    const durations = getCheckedServices().map((item) => item.service_duration);
    if (durations.length < 1) {
      return false;
    }
    return durations.every((item) => item === durations[0]);
  };

  const saveData = () => {
    console.log("llamada a sageData")
    if (services.every((item) => item.checked === false)) {
      setAddedMessage("Selecciona una prestacion");
    } else if (!checkSameDuration()) {
      setAddedMessage("Las prestaciones tienen diferente duración");
    } else {
      const data = {
        start_datetime: startDate.toISOString(),
        end_datetime: addMinutes(
          startDate,
          getCheckedServices()[0].service_duration,
        ).toISOString(),
        repeat: repeat,
        repeat_days: repeat ? getRepeatedDays() : [],
        services: getCheckedServices().map((item) => item.service_id),
      };
      console.log(data);
      axios
        .post("/api/add_timeslot", data, {
          withCredentials: true,
          headers: { "X-CSRF-TOKEN": Cookies.get("csrf_access_token") },
        })
        .then(() => {
          setAddedMessage("Guardado");
        })
        .catch(setAddedMessage("Horario ocupado"));
    }
    setSavedData(true)
    setToShow(false)
  };

  const handleOnChange = (position) => {
    const handledServices = services.map((item, index) => {
      if (index == position) {
        return { ...item, checked: !item.checked };
      } else {
        return item;
      }
    });
    setAddedMessage(undefined);
    setServices(handledServices);
  };

  return (
    <div className="form">
      <div
        className="modal-header"
        style={{ height: "20px", padding: "3px" }}
      >
      </div>
      <button
          type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <form className="editarperfilmodal" onSubmit={(e) => e.preventDefault()}>
        <div className="mb-3">
          <p className="titlecalendar">
            {" "}
            Selecciona tu próximo horario y día disponible
          </p>

          <i className="fa-regular fa-clock"></i>

          <DatePicker
            selected={startDate}
            onChange={(date) => {
              setAddedMessage(undefined);
              if (date != undefined) {
                setStartDate(date);
                resetRepeatedDays((date.getDay() + 6) % 7);
              }
            }}
            showTimeInput
            dateFormat="Pp"
            timeInputLabel="Horario"
            minDate={new Date()}
          />
        </div>
        <p className="titlecalendar">
          {" "}
          Qué prestación puedes hacer en este horaio:
        </p>
        <div
          style={{ marginBottom: "15px", marginTop: "15px" }}
          className="container-services-calendar"
        >
          {services != [] ? (
            services.map((item, idx) => {
              return (
                <div key={idx} className="display-block">
                  <input
                    className="checkmark"
                    type="checkbox"
                    id={`calendar-service-${idx}`}
                    checked={item.checked}
                    onChange={() => handleOnChange(idx)}
                  />
                  <label
                    className="display-inline-block label-calendar"
                    htmlFor={`calendar-service-${idx}`}
                  >
                    {item.service_name.length < 45
                      ? item.service_name
                      : item.service_name.substr(0, 44) + "..."}{" "}
                    ({item.service_duration} min)
                  </label>
                </div>
              );
            })
          ) : (
            <p>Agrega alguna prestación primero</p>
          )}
        </div>
        <div>
          <p className="titlecalendar">
            {" "}
            Quieres repetir este horario en otros días
          </p>
          <i className="fa-solid fa-rotate" />
          <select
            onChange={(e) => {
              setAddedMessage(undefined);
              e.target.value == semanalmente
                ? setRepeat(true)
                : setRepeat(false);
              resetRepeatedDays((startDate.getDay() + 6) % 7);
            }}
            style={{ marginBottom: "15px" }}
          >
            <option>No Repetir</option>
            <option>{semanalmente}</option>
          </select>
        </div>

        {repeat ? (
          <div className="repeated">
            {repeatedDays.map((item, idx) => {
              return (
                <button
                  key={`repeat-days-${idx}`}
                  style={
                    item.selected
                      ? { ...baseConfig, backgroundColor: "rgb(120, 180, 226)" }
                      : baseConfig
                  }
                  onClick={() => updateSelectedRepeatedDates(idx)}
                >
                  <span>{item.cap}</span>
                </button>
              );
            })}
          </div>
        ) : (
          <></>
        )}

        <div>{addedMessage}</div>
        <div
          className="modal-header"
          style={{ height: "20px", padding: "3px" }}
        ></div>
        <button
          className="button-login"
          onSubmit={(e) => e.preventDefault()}
          type="button"
          onClick={()=>{
            if(repeat == true ){saveData()}
            else{setTipoModal("onsave");setToShow(true)}
            }}
        >
          Agregar
        </button>
        <div
          className="modal-header"
          style={{ height: "20px", padding: "3px" }}
        ></div>
        <button
          className="button-login"
          data-dismiss={`${savedData?"modal":""}`}
          onClick={() =>{if(savedData){ setDate(new Date()),setSavedData(false)}
                        else{setTipoModal("nodata");setToShow(true)}}}
        >
          Listo
        </button>
      </form>
        <ModalButton toShow={toShow} onClose={() => setToShow(false)} saveData={saveData} tipomodal={tipomodal} getCheckedServices={getCheckedServices} services={services} setDate={setDate} />
      
    </div>
  );
}
