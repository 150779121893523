import React from "react";
import { useLocation } from 'react-router-dom';
import NavBar from "../navbar/navbar";

const RequestCompleted = () => {
 const location = useLocation();

  // Accede al estado transferido
  const { name, mailto, appointmentnumber } = location.state || {};
  return (
    <div style={{ marginTop: "22px" }}>
      <NavBar />
      <div className="centerin2">
     
<section className="confirmationformsend" style={{margin:"0 auto"}}>       <h1 className="fuenteh2" style={{ marginLeft: "0", fontSize:"150%" }}>
          Nueva reserva {appointmentnumber}
        </h1>    <p style={{fontSize:"120%",  margin:"15px"}}>Hola <b>{name}</b> gracias por tu solicitud.</p>
        <ul> 
          <li><p style={{fontSize:"120%", margin:"15px"}}>Nuestro equipo está revisando tu caso y te enviará una sugerencia de profesionales durante las próximas horas o de ser necesario te solicitaremos más información.</p></li>
        
        
        <li> <p style={{fontSize:"120%",  margin:"15px"}}>Te hemos enviado un correo a <b>{mailto} </b>con el resumen de tu solicitud, si no te llega durante los próximos minutos, por favor revisa tu carpeta de spam.</p></li>
</ul>
</section>        
      </div>
    </div>
  );
};

export default RequestCompleted;
