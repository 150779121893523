export function formatterRut(rut) {
  var actual = rut.toString().replace(/^0+/, "");
  if (actual != "" && actual.length > 1) {
    var sinPuntos = actual.replace(/\./g, "");
    var actualLimpio = sinPuntos.replace(/-/g, "");
    var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
    var rutPuntos = "";
    var i = 0;
    var j = 1;
    for (i = inicio.length - 1; i >= 0; i--) {
      var letra = !/^([0-9])*$/.test(inicio.charAt(i)) ? "" : inicio.charAt(i);
      rutPuntos = letra + rutPuntos;
      if (j % 3 == 0 && j <= inicio.length - 1) {
        rutPuntos = "." + rutPuntos;
      }
      j++;
    }
    var dv = actualLimpio.substring(actualLimpio.length - 1).toUpperCase();
    return (rutPuntos = rutPuntos + "-" + dv);
  }
  return actual;
}

function cleanRut(rut, withoutDv = false) {
  var sinPuntos = rut.toString().replace(/\./g, "");
  var actualLimpio = sinPuntos.replace(/-/g, "");
  actualLimpio =
    actualLimpio.slice(0, -1) + actualLimpio.slice(-1).toUpperCase();
  return withoutDv
    ? actualLimpio
    : actualLimpio.substring(0, actualLimpio.length - 1);
}

function validateRut(rut) {
  if (!/^0*(\d{1,3}(\.?\d{3})*)-?([\dkK])$/.test(rut.toString())) {
    return false;
  }
  rut = cleanRut(rut, true);
  var t = parseInt(rut.slice(0, -1), 10);
  var m = 0;
  var s = 1;
  while (t > 0) {
    s = (s + (t % 10) * (9 - (m++ % 6))) % 11;
    t = Math.floor(t / 10);
  }
  var v = s > 0 ? "" + (s - 1) : "K";
  return v === rut.slice(-1);
}

export const name_validation = {
  name: "Nombre",
  label: "Nombre",
  type: "text",
  id: "Nombre",
  placeholder: "Nombre",
  validation: {
    required: "Requerido",
    maxLength: {
      value: 30,
      message: "maximo 30 caracteres",
    },
  },
};

export const apellido_validation = {
  label: "Apellido",
  name: "Apellido",
  type: "text",
  id: "apellido",
  placeholder: "Apellido",
  validation: {
    required: "Requerido",
  },
};

export const rut_validation = {
  label: "Rut",
  name: "Rut",
  type: "text",
  id: "rut",
  placeholder: "Rut",
  validation: {
    required: "Requerido",
    validate: (value) => {
      if (!validateRut(value)) return "Rut inválido";
    },
  },
};

export const rut_validation_appointment = (rut) => {
      if (!validateRut(rut)) return false;
      return true
}


export const email_validation = {
  label: "Email",
  name: "Email",
  type: "text",
  id: "email",
  placeholder: "Email",
  validation: {
    required: "Requerido",
    pattern: {
      value: /\S+@\S+\.\S+/,
      message: "Ingrese un email válido",
    },
  },
};

export const nreg_validation = {
  label: "N de Registro",
  name: "N de Registro",
  type: "text",
  id: "nreg",
  placeholder: "Número de registro",
  validation: {
    required: "Requerido",
  },
};

export const password_validation = {
  label: "Password",
  name: "Password",
  type: "password",
  id: "pass",
  placeholder: "Password",
  validation: {
    required: "Requerido",
    minLength: {
      value: 8,
      message: "Largo mínimo de 8 carcteres",
    },
  },
};

export const whatsapp_validation = {
  label: "WhatsApp",
  name: "WhatsApp",
  type: "text",
  id: "phone",
  placeholder: "WhatsApp",
  validation: {
    required: "Requerido",
  }
}
