import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import PropTypes from "prop-types";
import "./WeeklyCalendar.css";

export function WeeklyCalendar({ hours, setdateInput,setDate,services }) {
  WeeklyCalendar.propTypes = {
    hours: PropTypes.array,
    services: PropTypes.array,
    setdateInput: PropTypes.func,
    setDate: PropTypes.func,
  };
  const hours_ = Array.from({ length: 16 }, (_, i) => `${i + 7}:00`);
  // eslint-disable-next-line no-unused-vars

  const getStrHour = (hour) => {
    const start = new Date(hour["start"]).toLocaleString("es", {
      hour: "numeric",
      minute: "numeric",
    });
    const end = new Date(hour["end"]).toLocaleString("es", {
      hour: "numeric",
      minute: "numeric",
    });
    return [start, end];
  };



  function getMinutesFromTime(time) {
    // Verifica que el formato sea válido
    if (!/^\d{1,2}:\d{2}$/.test(time)) {
      throw new Error("El formato de la hora debe ser 'hh:mm'" + time);
    }

    // Divide el string en horas y minutos
    const [hours, minutes] = time.split(":").map(Number);

    // Asegúrate de que sean valores válidos
    if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
      throw new Error("La hora o los minutos no son válidos");
    }

    return [hours, minutes];
  }

const [toshow,setToShow]=useState("all")
  useEffect(() => {
    setToShow(toshow);
  }, [toshow]);




 const servicestoshow = (services) => {
  return (
    <><div style={{display:"flex", justifyContent:"space-between", alignItems:"flex-end", width:"100%"}}>
        <div style={{ display: "flex", justifyContent: "center", marginLeft:"35px" }}>
        <a href="#" data-toggle="modal" data-target="#editCalendarModal">
          <button
            className={`button-login profilebutton`}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width:"200px"
            }}
          >
            <div>Agregar horario de atención </div>
            <div>
              <i
                className={`fa-solid fa-plus`}
                style={{ fontSize: "14px", height: "100%" }}
              ></i>
            </div>
          </button>
        </a>
      </div>
      <div style={{display:"flex"}}>
      <p>Mostrar eventos</p>
    <select onChange={(e) => setToShow(e.target.value)}>
      <option value="all">Todos los eventos</option>
      <option value="0">Bloques disponibles</option>
      <option value="1">Bloques Agendados</option>
      {services.map((service, index) => (
        <option key={index} value={service.service_id}>
          {service["service_name"]}
        </option>
      ))}
    </select></div></div></>
  );
};

  
  const setDisplayedAcgivity = (hr) => {
    const porcentajeHora =
      (Number(getMinutesFromTime(getStrHour(hr)[0])[1]) / 60) * 100;
    const init =
      Number(getMinutesFromTime(getStrHour(hr)[0])[0]) * 60 +
      Number(getMinutesFromTime(getStrHour(hr)[0])[1]);
    const finit =
      Number(getMinutesFromTime(getStrHour(hr)[1])[0]) * 60 +
      Number(getMinutesFromTime(getStrHour(hr)[1])[1]);
    const alturaHora = ((finit - init) * 50) / 60;
    
    return {
      top: `${porcentajeHora}%`,
      height: `${alturaHora}px`,
      backgroundColor: hr["status"] != 0 ? "#a9a9a9a9" : "",
      
    };
  };

  const getActivityForSlot = (day_, hour) => {
    const hoursByday = hours.find(
      (activity) =>
        activity.date.toLocaleDateString("es", {
          weekday: "short",
          day: "numeric",
        }) === day_ && day_,
    );
    return hoursByday.hours.filter(
      (activity) =>
        getMinutesFromTime(getStrHour(activity)[0])[0] ===
        getMinutesFromTime(hour)[0],
    );
    
  };

  const displayText = (calendarEntry) => {
    if (calendarEntry["status"] == 0) {
      return "Disponible";
    } else if (calendarEntry["status"] == 1) {
      return "Agendado";
    } else if (calendarEntry["status"] == 2) {
      return "Bloqueado";
    }
    return "";
  };
    const removeEvent = (event_id) => {
    axios
      .post(
        "/api/delete_timeslot",
        { id: event_id },
        {
          withCredentials: true,
          headers: { "X-CSRF-TOKEN": Cookies.get("csrf_access_token") },
        },
      )
      .then(() => console.log("removed"))
      .catch((error) => console.log(error));
  };
  return (
    <div className="calendar-container">
      {servicestoshow(services)}
      <div className="calendar-header">
        {hours.map((day, idx) => (
          <div key={`header-${idx}`} className="calendar-header-cell">
            {day["date"].toLocaleDateString("es", {
              weekday: "short",
              day: "numeric",
            })}
          </div>
        ))}
      </div>
      <div
        className="d-flex"
        style={{ height: "500px", overflowY: "scroll", marginBottom: "50px" }}
      >
        <div className="contenedorkeyhora2">
          {" "}
          {hours_.map((hour, idx) => (
            <div key={`hour1-${idx}`} className="contenedorkeyhora">
              {hour}
            </div>
          ))}
        </div>
        <div className="calendar-body">
          {hours_.map((hour) =>
            hours.map((day) => {
              const activity = getActivityForSlot(
                day["date"].toLocaleDateString("es", {
                  weekday: "short",
                  day: "numeric",
                }),
                hour
              );
              return (
                <div
                  key={`hh-${day["date"].toLocaleDateString("es", {
                    weekday: "short",
                    day: "numeric",
                  })}-${hour}`}
                >
                  {/* <a
                    className={activity.length>0?"":"buttoncalendarmodal"}
                    href={activity.length>0?undefined:"#"}
                    data-toggle={activity.length>0?undefined:"modal"}
                    data-target={activity.length>0?undefined:"#editCalendarModal"}
                   
                    onClick={() => {
                      console.log(activity)
                      const dateString = day["date"];
                      const timeString = hour;
                      const newdate = new Date(dateString);
                      // eslint-disable-next-line no-unused-vars
                      const [hours, minutes] = timeString
                        .split(":")
                        .map(Number);
                      newdate.setHours(hours, 0, 0, 0);
                      setdateInput(newdate);
                    }}
                  > */}
                    <div className="calendar-cell">
                      <a className="buttoncalendarmodal cell-time1" style={{height:"25px"}} 
                   
                    href="#"
                    data-toggle="modal"
                    data-target="#editCalendarModal"
                    onClick={() => {
                      console.log(activity)
                      const dateString = day["date"];
                      const timeString = hour;
                      const newdate = new Date(dateString);
                      // eslint-disable-next-line no-unused-vars
                      const [hours, minutes] = timeString
                        .split(":")
                        .map(Number);
                      newdate.setHours(hours, 0, 0, 0);
                      console.log(newdate)
                      setdateInput(newdate);
                    }}>

                    </a>
                      <a className="buttoncalendarmodal cell-time2"style={{height:"25px"}}
                     href="#"
                    data-toggle="modal"
                    data-target="#editCalendarModal"
                      onClick={() => {
                      console.log(activity)
                      const dateString = day["date"];
                      const timeString = hour;
                      const newdate = new Date(dateString);
                      // eslint-disable-next-line no-unused-vars
                      const [hours, minutes] = timeString
                        .split(":")
                        .map(Number);
                      newdate.setHours(hours, 30, 0, 0);
                      setdateInput(newdate);
                    }}></a>
                      {activity &&
                        activity.map((actv, idx) => (
                        (
                          <div
                            key={`actv-${hour}-${idx}-${actv.cal_id}`}
                            className={`cell-activity ${(toshow==="all"|| toshow===actv["service_id"].toString() || toshow===actv["status"].toString())?"showactivity":"notshowactivity"}`}
                            style={setDisplayedAcgivity(actv) }
                          > 
                            {displayText(actv)}
                            
                          {displayText(actv)=="Disponible"&&(<span> <i
                      className={`fa-solid fa-trash`}
                      onClick={() => {
                        removeEvent(actv["cal_id"]);
                        setDate(new Date(actv["start"])); 
                      }}style={{zIndex:"999"}}
                    ></i></span>)}
                          </div>
                       ) ))}
                    </div>
                  {/* </a> */}
                </div>
              );
            }),
          )}
        </div>
      </div>
      {/* <Modal_ isOpen={true} id="editCalendarModal">
        <EditCalendarModal
          services={services}
          setServices={setServices}
          setDate={setDate}
        />
      </Modal_> */}
    </div>
  );
}
