import React, { useState,useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import InputField from './inputfield';
import axios from "axios";
import PropTypes from "prop-types";
import "../login/login.css";
import {

  formatterRut,
  rut_validation_appointment,
} from "../registration/validators";


import "react-datepicker/dist/react-datepicker.css";

import "../agenda-calendario/Calendario.css";





const Form = ({ handleModalAgendar , textopaciente}) => {
 
  const [formAppointment, setFormAppointment] = useState({ name: "",
                                            lastname: "",
                                            email: "",
                                            rut: "",
                                            genero: "",
                                            edad: "",
                                            phone:"",
                                            prevsalud:"Fonasa",
                                            date:new Date(),
                                            msg:textopaciente
                                            });
  const [errors, setErrors] = useState({});
  const handlePopState = () => {
      // Buscar y cerrar manualmente todos los modales abiertos
      const modals = document.querySelectorAll('.modal.show');
      modals.forEach(modal => {
        // Simular un clic en el botón de cerrar del modal
        const closeButton = modal.querySelector('[data-bs-dismiss="modal"]');
        if (closeButton) {
          closeButton.click();
        }
      });

      // Eliminar manualmente todos los backdrops
      const backdrops = document.querySelectorAll('.modal-backdrop');
      backdrops.forEach(backdrop => backdrop.remove());
    };
    
      useEffect(() => {
  handleChange("msg",textopaciente)
  }, [textopaciente]);

  const closemodal = () =>{
    document.body.classList.remove('modal-open');
    handleModalAgendar(false);
    handlePopState();
  }
  // useEffect(() => {
  //   if (itemToEdit) setFormAppointment(itemToEdit);
  // }, [itemToEdit]);

  const validate = () => {
    const newErrors = {};
    if (!formAppointment.name) newErrors.name = "El nombre es obligatorio.";
    if (!formAppointment.rut) newErrors.rut = "El rut es obligatorio";
    if (!rut_validation_appointment(formatterRut(formAppointment.rut))) newErrors.rut = "Rut inválido ";
    if (!formAppointment.edad) newErrors.edad = "La edad es obligatoria";
    if (!formAppointment.phone) newErrors.phone = "WhatsApp obligatorio";
    
    return newErrors;
  };


  const handleChange = (field, value) => {
    setFormAppointment((prev) => ({ ...prev, [field]: value }));
  };

  const navigate = useNavigate();
  
  const handleSubmit = (e) => {
    
    e.preventDefault();
    const validationErrors = validate();
    console.log(formAppointment)
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
     axios
      .post("/api/agenda", formAppointment)
      .then((response) => {
        // Manejar la respuesta exitosa aquí
        console.log("Respuesta:", response.data);
        
      setFormAppointment({ name: "",
                          lastname: "",
                          email: "",
                          rut: "",
                          genero: "",
                          edad: "",
                          phone:"",
                          prevsalud:"Fonasa",
                        msg:"" }); 
                      
      closemodal();
      navigate("/confirmacion_solicitud",{
      state: {
        name: formAppointment.name,
        mailto: formAppointment.email,
      appointmentnumber:response.data  
      },
    });
      })
      .catch((error) => {
        // Manejar errores aquí
        console.error("Error:", error);
      });
      
    // Limpia el formulario
  };

  return (
    <form onSubmit={handleSubmit} style={{ maxWidth: "400px", margin: "0 auto" }} className='login-form'>
      <InputField
        label="Nombre"
        value={formAppointment.name}
        onChange={(value) => handleChange("name", value)}
        error={errors.name}
        placeholder={"Nombre"}
      />
      <InputField
        label="Apellido"
        value={formAppointment.lastname}
        onChange={(value) => handleChange("lastname", value)}
        error={errors.lastname}
        placeholder={"Apellidos"}
      />
      <InputField
        label="Rut"
        value={formAppointment.rut}
        onChange={(value) => handleChange("rut", value)}
        error={errors.rut}
        placeholder={"Rut Paciente"}
      />
      <InputField
        label="Edad"
        value={formAppointment.edad}
        onChange={(value) => handleChange("edad", value)}
        error={errors.edad}
        placeholder={"Edad paciente"}
      />
            <div className='div-selector-form' style={{margin:"10px auto", display:"flex", justifyContent:"space-between"}}>
      <label>Género: </label>
      <select
              className="gender_selector"
              style={{minWidth:"40%",maxWidth:"40%", backgroundColor:"#f2f2f2", borderRadius:"5px"}}
              id="selector_prevsalud"
               onChange={(e) => handleChange("genero",e.target.value)}
               required 
            > 
              <option value="Femenino">Femenino</option>
              <option value="Masculino">Masculino</option>
              <option value="Nobinario">Transgenero</option>
              <option value="Nobinario">Nobinario</option>
              <option selected value="Otro">
                Otro
              </option>
            </select>
</div>
      <InputField
        label="Correo"
        value={formAppointment.email}
        onChange={(value) => handleChange("email", value)}
        error={errors.email}
        placeholder={"Correo"}
        type="email"
      />
      <InputField
        label="Celular"
        value={formAppointment.phone}
        onChange={(value) => handleChange("phone", value)}
        error={errors.phone}
        placeholder={"WhatsApp"}
      />
      
      <div className="div-selector-form" style={{margin:"10px auto", display:"flex", justifyContent:"space-between"}}>
      <label>Previsión de Salud:</label>
      <select
              className="gender_selector"
              style={{minWidth:"40%",maxWidth:"40%", backgroundColor:"#f2f2f2", borderRadius:"5px"}}
              id="selector_prevsalud"
               onChange={(e) => handleChange("prevsalud",e.target.value)}
               required 
               
            > 
           
              <option selected value="Fonasa">Fonasa</option>
              <option value="Isapre">Isapre</option>
            </select>
</div>

      {/* <InputField
        label="Foto de perfil (URL)"
        type="url"
        value={formData.profilePicture}
        onChange={(value) => handleChange("profilePicture", value)}
      /> */}
        <button
                      className="boton-ovalado"
      
                      type='submit'
                    >
                      <b>Enviar solicitud</b>
                     
                    </button>
    </form>
  );
};

 Form.propTypes = {
  itemToEdit: PropTypes.array,
  handleModalAgendar: PropTypes.func,
  textopaciente: PropTypes.string,
    
  }
export default Form;
