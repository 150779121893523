import React, { useState, useRef } from "react";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";

import {
  apellido_validation,
  email_validation,
  formatterRut,
  name_validation,
  rut_validation,
  password_validation,
  whatsapp_validation,
} from "./validators";
import Logo from "./../img/LogoVincularse.png";
import { Input } from "./Input";

const RegistrationForm = () => {
  const closeRef = useRef();
  const [formularioEnviado, setFormularioEnviado] = useState(false);

  const methods = useForm();

  const onSubmit = methods.handleSubmit((data) => {
    data.Rut = formatterRut(data.Rut);
    axios
      .post("/api/register", data)
      .then(() => {
        // Simulate click to close modal
        closeRef.current.click();
        setFormularioEnviado(true);
      })
      .catch((error) => {
        if (error.response.data.message === "rut") {
          methods.setError("Rut", {
            type: "custom",
            message: "Rut ya registrado",
          });
        } else if (error.response.data.message === "email") {
          methods.setError("Email", {
            type: "custom",
            message: "Email ya registrado",
          });
        }
      });
  });

  if (formularioEnviado) {
    return <Navigate to="/registration_complete" />;
  }

  return (
    <div className="form">
      <div className="modal-header">
        <img
          className="imghead"
          href="/"
          src={Logo}
          alt="Descripción de la imagen"
          style={{ maxWidth: "50%" }}
        />

        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          ref={closeRef}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <FormProvider {...methods}>
        <form
          onSubmit={(e) => e.preventDefault()}
          noValidate
          className="login-form"
        >
          <Input {...name_validation} />
          <Input {...apellido_validation} />
          <Input {...rut_validation} />
          <Input {...email_validation} />
          <Input {...password_validation} />
          <Input {...whatsapp_validation} />

          <button type="button" className="button-login" onClick={onSubmit}>
            Registrarse
          </button>
        </form>
      </FormProvider>
    </div>
  );
};
export default RegistrationForm;
